.select {
  border: 4px solid #aa2233;
  color: #fff;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  font-family: inherit;
  background-color: #222222;
  border-radius: 4px;
  width: 120px;
  align-self: center;
  margin-bottom: 16px;
  margin-top: 16px;
}
