* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #181818;
  border-radius: 8px;
}
