.container {
  padding: 32px 32px 86px 32px;
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  max-height: 100%;
}

.editor {
  flex: 1 0 75%;
  height: 100%;
  box-shadow: 0 0 50px -25px black;
}

.output {
  flex: 1 0 25%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.outputButtons {
  display: flex;
  justify-content: center;
  gap: 16px;
}

pre.outputWrapper {
  flex: 1;
  background-color: #2c2c2c;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: auto;
  border-radius: 8px;

  code.outputContent {
    counter-reset: line;
    max-height: 100%;
    font-size: 14px;
    font-family: monospace;
    white-space: pre-wrap;
    overflow: visible;
  }
}

.outputLine {
  display: block;
  overflow: visible;
  position: relative;
  padding-left: 26px;

  &:before {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    counter-increment: line;
    content: counter(line);
    border-right: 1px solid #676767;
    padding-right: 4px;
    margin-right: 0.5em;
    color: #676767;
    font-size: 12px;
    text-align: right;
    width: 16px;
  }
}

.run {
  border: 4px solid #aa2233;
  color: #fff;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  font-family: inherit;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
}

.iframe {
  flex: 1 0 80%;
  border: none;
  min-width: 100%;
}
